.projects {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.projects h1 {
  font-family: Poppins;
}

.projects span {
  padding-left: 0.5rem;
}

.projects .projectTitle {
  text-align: center;
  color: #3e497a;
  font-size: 60px;
  font-weight: bolder;
}

.projectList {
  height: auto;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  place-items: center;
}

.projectItem {
  border-radius: 15px;
  width: 400px;
  height: 300px;
  margin: 40px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.projectItem:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.projectItem .bgImage {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.projectItem h1 {
  font-size: 25px;
  font-family: Poppins;
}

@media only screen and (max-width: 1380px) {
  .projectList {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .projectItem {
    width: 320px;
  }
}

@media only screen and (max-width: 1150px) {
  .projectItem {
    width: 235px;
  }
}

@media only screen and (max-width: 900px) {
  .projectList {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 900px) {
  .projectList {
    width: 100%;
  }
  .projectItem {
    width: 70%;
    height: 80%;
  }
}
