.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family:Poppins;
  margin-top:1rem;
}

.about {
  background-color: #f9f9f9;
  height:100%;
  display: flex;
  flex-direction: row;
  background-repeat: no-repeat;
  background-size: 3000px;
  background-position: 90%;
  position: relative;
  align-items: center;
  max-width:96%;
}

.details {
  text-align: left;
}

.details h1 {
  color: black;
  font-size: 5rem;
  margin-bottom: 1.5rem;
  line-height:1.1;
}

.details p {
  color: #555;
  max-width: 600px;
  text-align: left;
  margin-top:0.5rem;
}

.details .icons {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  margin-top: 1.5rem;

}

.icons a{
    color:#5e5e5c;
}


.icons a:hover{
    color:#00acfc;
}

.photo {
  margin-top: 12rem;
  margin-left: 5rem;
  position: relative;
}

.photo img {
  height: 450px;
  border: 4px solid;
  border-color:black;
  border-radius: 60% 40% 30% 50%/60% 30% 70% 40%;
  position: relative;
  animation: morph 5s ease-in-out 0s infinite normal none running;
}

@keyframes morph {
  0%, 100% {
    border-radius: 60% 40% 30% 50%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 70% 30% 70%;
  }
 }

.tech {
  display: flex;
  flex-direction:row;
  align-items:center;
  bottom: 0;
  color: #00ab00;
  font-size: 1rem;
  position: absolute;
  left:0;


}

.tech h3 {
  border-right: 2px solid rgb(45, 46, 50,0.5);
  margin-right: 1rem;
  padding-right: 2rem;

}

.tech ul {
  display: flex;
  flex-wrap: wrap;
  gap: 1.8rem;
  list-style: none;
}

.tech ul li:hover{
  cursor: pointer;
}

@media (max-width:1240px){

.photo{
    margin-bottom:7rem;
}

.photo img{
    height:380px;
}
.tech{
    text-align:center;
    max-width:100%;
    justify-content:center;

}

.details h1{
    line-height:1.1;
    margin-bottom:1.5rem;
}

}

@media (max-width: 980px) {
  html,body {
      width:100%;
      height:100%;
      margin:0;
      padding:0;
      overflow-x:hidden;
  
  }
  
  .home {
    flex-direction: column-reverse;
    align-items: center;
    height:auto;
  }
  
  .about  {
      flex-direction:column-reverse;
      justify-content: center;
      text-align:center;
      gap:1.5rem;
      max-width: 100%;
      height:auto;
  
    }
  
  .details h1 {
  
    font-size: 4rem;
    line-height:1.1;
    margin-bottom:1.5rem;
  
    }
  
  .photo {
      justify-content:center;
      margin-top:5rem;
      position:relative;
      margin-right:5rem;
      margin-bottom:-2rem;
  }
  
  .photo img {
      height:20rem;
      width:20rem;
      justify-content:center;
  }
  
  .details {
      flex-direction:column;
      text-align:center;
      position:relative;
  
  
  
  }
  
  .details h1{
    padding-left:1rem;
    padding-right:1rem;
  }
  
  .details p{
      text-align:center;
      justify-content:center;
      padding-left:1rem;
      padding-right: 1rem;
  
  
  
  
  
  
  }
  
  .icons {
      justify-content:center;
      padding-right: 1rem;
  
  }
  
  .tech{
      text-align:center;
      margin-bottom:-10rem;
      flex-direction:column;
      max-width:100%;
      right:0;
  
  }
  
  .tech h3{
      border-bottom: 2px solid rgb(45, 46, 50,0.5);
      border-right:none;
      padding-right:0rem;
      padding-bottom:0.5rem;
      margin-bottom:0.2rem;
  }
  
  

  }
  
  @media (max-width: 485px) {
    
    .details {
      max-width:20rem;
    }
  
  
    .details h1 {
      /* ... (your existing styles) ... */
      font-size: 3.5rem; /* Decrease font size for better fit */
    }
  
    .details p {
      /* ... (your existing styles) ... */
       /* Adjust maximum width for better text wrapping */
      max-width:30rem;
      padding-left: 0rem;
      padding-right: 0rem;
    }
  
    .photo img {
      /* ... (your existing styles) ... */
      height: 300px; /* Adjust image height */
    }
  
    .tech {
      /* ... (your existing styles) ... */
      max-width:30rem;
      text-align: center;
      margin-bottom: -15rem;
      /* Adjust margin for spacing */
    }
  
    .tech div{
      max-width:19rem;
    }
  
    .tech h3 {
      /* ... (your existing styles) ... */
  
      border-bottom: 2px solid rgba(45, 46, 50, 0.5);
      border-right: none;
      padding-right: 0rem;
      padding-bottom: 0.5rem;
      margin-bottom: 0.2rem;
      font-size: 1rem; /* Adjust font size for better fit */
    }
  
  
  }