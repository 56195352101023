
.project {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family:Poppins;
}

.project .p1 {
  box-sizing: border-box;
  background-color: #fff;
  width: 75rem;
  height: 28rem;
  display: flex;
  flex-direction: row;
  gap: 7rem;
  padding: 1.5rem;
  border-radius: 1.5rem;
}

.project .p2 {
  border-radius: 1.0rem;
  height: auto;
  background-color: '';
  width: 40rem;
  overflow: hidden;
}

.project .p2 img {
  width: 100%;
  height:100%
  
}

.project .p3 {
  background-color: '';
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  text-align: center;
  width: 20rem;
  box-sizing: border-box;
}

.project .p3 h3 {
  text-transform: uppercase;
  margin-top: 5rem;
  color: #2e2d32;
  font-size: 1.2rem;
  position: relative;
}

.project .p3 p {
  font-size: 1.2rem;
  color: #767676;
  text-transform: none;
  font-weight: 500;
  text-align: center;

}

.project .p3 .condition1,
.project .p3 .condition2,
.project .p3 .condition5 {
  display: flex;
  gap: 1rem;
  justify-content: center;
  box-sizing: border-box;
}

.project .p3 .condition1 p,
.project .p3 .condition2 p,
.project .p3 .condition5 p {
  box-shadow: 10px;
  font-size: 1.2rem;
  color: #000;
  font-family: sans-serif;
  border-style: none;
  padding: 0.6rem;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

.project .p3 .condition3,
.project .p3 .condition4,
.project .p3 .condition6 {
  display: flex;
  justify-content: center;
  padding: 2rem;
  gap: 1rem;
  font-size: 20px;
}

.project .p3 .condition3 h3,
.project .p3 .condition4 h3,
.project .p3 .condition6 h3 {
  justify-content:center;
  text-align:center;
  margin-top:0px;
  font-size:25px;
}

.project .p3 .condition3 a,
.project .p3 .condition4 a,
.project .p3 .condition6 a {
  margin-top: 7px;
  color:black;

}


@media (max-width: 1180px) {
    body{
        overflow-x:hidden;
    }

    .project .p1 {
        gap:6rem;
        flex-direction:column;
        height:100%;
        width:70%;
        justify-content:center;
        text-align:center;
        align-items:center;
        padding-top:1.5rem;
        margin-top:10rem;

    }

    .project .p2 {
        width:100%;
    }
    
    .project .p2 img{
        width:100%;
        height:100%;


    }


    .project .p3{
        margin-top:-8rem;


    }

}

@media (max-width: 800px){
    .project .p1{
        width:90%;
    }


}

@media (max-width: 360px){
    .project .p3{
        width:90%;
}
    .project .p3 .condition2 p{
        font-size:1rem;
    }

    .project .p3 .condition1 p{
        font-size:1rem;
    }

}

@media (max-height: 800px) {
    .project {
        height: calc(100vh - 20px);
    }

    .project .p2 {
        min-height: 35%; /* Your style for max-height 750px or lower */
        margin-top: 3rem; /* Your style for max-height 750px or lower */
    }
}


@media (max-height: 710px) {
    .project {
        height: calc(100vh - 5px);
    }

    .project .p2 {
        min-height: 25%; /* Your style for max-height 750px or lower */
        margin-top: 2rem; /* Your style for max-height 750px or lower */
    }
}

@media (max-height: 620px) {
    .project {
        height: calc(100vh - 5px);
    }

    .project .p2 {
        min-height: 20%; /* Your style for max-height 750px or lower */
        margin-top: 3rem; /* Your style for max-height 750px or lower */
    }
}