.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f9f9f9;
}

body {
    margin:0;
    padding:0;
    overflow-x:hidden;

}
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family:Poppins;
  margin-top:1rem;
}

.about {
  background-color: #f9f9f9;
  height:100%;
  display: flex;
  flex-direction: row;
  background-repeat: no-repeat;
  background-size: 3000px;
  background-position: 90%;
  position: relative;
  align-items: center;
  max-width:96%;
}

.details {
  text-align: left;
}

.details h1 {
  color: black;
  font-size: 5rem;
  margin-bottom: 1.5rem;
  line-height:1.1;
}

.details p {
  color: #555;
  max-width: 600px;
  text-align: left;
  margin-top:0.5rem;
}

.details .icons {
  display: flex;
  flex-direction: row;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  margin-top: 1.5rem;

}

.icons a{
    color:#5e5e5c;
}


.icons a:hover{
    color:#00acfc;
}

.photo {
  margin-top: 12rem;
  margin-left: 5rem;
  position: relative;
}

.photo img {
  height: 450px;
  border: 4px solid;
  border-color:black;
  border-radius: 60% 40% 30% 50%/60% 30% 70% 40%;
  position: relative;
  -webkit-animation: morph 5s ease-in-out 0s infinite normal none running;
          animation: morph 5s ease-in-out 0s infinite normal none running;
}

@-webkit-keyframes morph {
  0%, 100% {
    border-radius: 60% 40% 30% 50%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 70% 30% 70%;
  }
 }

@keyframes morph {
  0%, 100% {
    border-radius: 60% 40% 30% 50%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 70% 30% 70%;
  }
 }

.tech {
  display: flex;
  flex-direction:row;
  align-items:center;
  bottom: 0;
  color: #00ab00;
  font-size: 1rem;
  position: absolute;
  left:0;


}

.tech h3 {
  border-right: 2px solid rgb(45, 46, 50,0.5);
  margin-right: 1rem;
  padding-right: 2rem;

}

.tech ul {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1.8rem;
  gap: 1.8rem;
  list-style: none;
}

.tech ul li:hover{
  cursor: pointer;
}

@media (max-width:1240px){

.photo{
    margin-bottom:7rem;
}

.photo img{
    height:380px;
}
.tech{
    text-align:center;
    max-width:100%;
    justify-content:center;

}

.details h1{
    line-height:1.1;
    margin-bottom:1.5rem;
}

}

@media (max-width: 980px) {
  html,body {
      width:100%;
      height:100%;
      margin:0;
      padding:0;
      overflow-x:hidden;
  
  }
  
  .home {
    flex-direction: column-reverse;
    align-items: center;
    height:auto;
  }
  
  .about  {
      flex-direction:column-reverse;
      justify-content: center;
      text-align:center;
      grid-gap:1.5rem;
      gap:1.5rem;
      max-width: 100%;
      height:auto;
  
    }
  
  .details h1 {
  
    font-size: 4rem;
    line-height:1.1;
    margin-bottom:1.5rem;
  
    }
  
  .photo {
      justify-content:center;
      margin-top:5rem;
      position:relative;
      margin-right:5rem;
      margin-bottom:-2rem;
  }
  
  .photo img {
      height:20rem;
      width:20rem;
      justify-content:center;
  }
  
  .details {
      flex-direction:column;
      text-align:center;
      position:relative;
  
  
  
  }
  
  .details h1{
    padding-left:1rem;
    padding-right:1rem;
  }
  
  .details p{
      text-align:center;
      justify-content:center;
      padding-left:1rem;
      padding-right: 1rem;
  
  
  
  
  
  
  }
  
  .icons {
      justify-content:center;
      padding-right: 1rem;
  
  }
  
  .tech{
      text-align:center;
      margin-bottom:-10rem;
      flex-direction:column;
      max-width:100%;
      right:0;
  
  }
  
  .tech h3{
      border-bottom: 2px solid rgb(45, 46, 50,0.5);
      border-right:none;
      padding-right:0rem;
      padding-bottom:0.5rem;
      margin-bottom:0.2rem;
  }
  
  

  }
  
  @media (max-width: 485px) {
    
    .details {
      max-width:20rem;
    }
  
  
    .details h1 {
      /* ... (your existing styles) ... */
      font-size: 3.5rem; /* Decrease font size for better fit */
    }
  
    .details p {
      /* ... (your existing styles) ... */
       /* Adjust maximum width for better text wrapping */
      max-width:30rem;
      padding-left: 0rem;
      padding-right: 0rem;
    }
  
    .photo img {
      /* ... (your existing styles) ... */
      height: 300px; /* Adjust image height */
    }
  
    .tech {
      /* ... (your existing styles) ... */
      max-width:30rem;
      text-align: center;
      margin-bottom: -15rem;
      /* Adjust margin for spacing */
    }
  
    .tech div{
      max-width:19rem;
    }
  
    .tech h3 {
      /* ... (your existing styles) ... */
  
      border-bottom: 2px solid rgba(45, 46, 50, 0.5);
      border-right: none;
      padding-right: 0rem;
      padding-bottom: 0.5rem;
      margin-bottom: 0.2rem;
      font-size: 1rem; /* Adjust font size for better fit */
    }
  
  
  }
.projects {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.projects h1 {
  font-family: Poppins;
}

.projects span {
  padding-left: 0.5rem;
}

.projects .projectTitle {
  text-align: center;
  color: #3e497a;
  font-size: 60px;
  font-weight: bolder;
}

.projectList {
  height: auto;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  place-items: center;
}

.projectItem {
  border-radius: 15px;
  width: 400px;
  height: 300px;
  margin: 40px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.projectItem:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.projectItem .bgImage {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.projectItem h1 {
  font-size: 25px;
  font-family: Poppins;
}

@media only screen and (max-width: 1380px) {
  .projectList {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .projectItem {
    width: 320px;
  }
}

@media only screen and (max-width: 1150px) {
  .projectItem {
    width: 235px;
  }
}

@media only screen and (max-width: 900px) {
  .projectList {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 900px) {
  .projectList {
    width: 100%;
  }
  .projectItem {
    width: 70%;
    height: 80%;
  }
}

.aboutsection {
    font-size: large;
    color:lightslategray;
    height:auto;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height:auto;
    font-family: 'Poppins';
}

.aboutsection h1{
    text-align: center;
    color: black;
}

.aboutsection span {
    padding-left: 0.5rem;
}

.aboutcontainer {
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    gap: 2rem;
    width:100%;
    color:#333232;
}
.professional {
    margin: 8rem;
}

.professional h4{
    justify-content: center;
    text-align: center;
    padding-right: 2rem;
    color: #252629;
    font-weight: bold;
    font-size: larger;
}

.professional p{
    line-height: 1.8;
}

.personal {
   margin: 8rem;
}

.personal h4{
    text-align: center;
    justify-content: center;
    color: #252629;
    font-weight: bold;
    font-size: larger;
}

.personal p{
    line-height: 1.8;
}

.contactcontainer {
    margin-top:1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap:3rem;
    gap:3rem;
    color: black;
}



.contactcontainer a{
    display: block;
    text-decoration: none;
    color: gray;
}

.contactcontainer a:hover{
    cursor: pointer;
    text-decoration: underline;
    color:orange;
}

@media (max-width:1640px) {
    .professional,
    .personal{
        margin:6rem;
    }
}

@media (max-width:1290px) {
    .aboutcontainer{
        font-size: 1rem;
    }
    .professional,
    .personal{
        margin:5rem;
    }
}


@media (max-width:1020px) {
    .aboutcontainer{
        font-size: 1rem;
    }
    .professional,
    .personal{
        margin:2rem;
    }
}

@media (max-width:840px) {
    .aboutcontainer{
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        grid-gap: 0rem;
        gap: 0rem;
    }
    .professional,
    .personal{
        margin:2rem;
    }

    .contactcontainer{
        flex-direction: column;
        grid-gap:0;
        gap:0;
    }

    .contactcontainer .linkedina{
        align-items: center;
        justify-content: center;
        padding-left: 8rem;
    }
}

@media (max-width: 400px) {
    .contactcontainer {
        font-size: 1rem;
    }

}
.navbar {
  width: 100%;
  height: 100px;
  background: #fff;
  box-shadow:0px 0px 10px rgba(0,0,0,.10);
  font-family:Poppins;
}

.expanded {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  transition: 0.5s;

}

.expanded .links{
    flex-direction:column;
    display:flex;
    flex-direction:column;
}

.links {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;

}

.navbar a {
  color: black;
  text-decoration: none;
  margin: 20px;
  font-size: 25px;
}

.toggleButton {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.toggleButton svg {
  font-size: 50px;
}
.toggleButton button {
  margin-right: 20px;
  background-color: transparent;
  border: none;
  color: black;
  cursor: pointer;
}


@media only screen and (max-width: 900px) {
  .navbar a {


  }


}

@media only screen and (max-width: 600px) {
  .toggleButton {
    display: flex;

  }

  .links{
    display:none;
  }


}

@media only screen and (min-width: 600px) {
  .toggleButton {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }


}


.footer {
  width: 100%;
  height: 150px;
  background: #2d2e32;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  margin-top: 260px;

}

.socialMedia svg {
  color: white;
  margin: 20px;
  font-size: 70px;
  cursor: pointer;
}

.footer p {
  color: white;
}

@media only screen and (max-width: 600px) {
  .footer svg {
    font-size: 50px;
  }
}

.project {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family:Poppins;
}

.project .p1 {
  box-sizing: border-box;
  background-color: #fff;
  width: 75rem;
  height: 28rem;
  display: flex;
  flex-direction: row;
  grid-gap: 7rem;
  gap: 7rem;
  padding: 1.5rem;
  border-radius: 1.5rem;
}

.project .p2 {
  border-radius: 1.0rem;
  height: auto;
  background-color: '';
  width: 40rem;
  overflow: hidden;
}

.project .p2 img {
  width: 100%;
  height:100%
  
}

.project .p3 {
  background-color: '';
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  text-align: center;
  width: 20rem;
  box-sizing: border-box;
}

.project .p3 h3 {
  text-transform: uppercase;
  margin-top: 5rem;
  color: #2e2d32;
  font-size: 1.2rem;
  position: relative;
}

.project .p3 p {
  font-size: 1.2rem;
  color: #767676;
  text-transform: none;
  font-weight: 500;
  text-align: center;

}

.project .p3 .condition1,
.project .p3 .condition2,
.project .p3 .condition5 {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
  box-sizing: border-box;
}

.project .p3 .condition1 p,
.project .p3 .condition2 p,
.project .p3 .condition5 p {
  box-shadow: 10px;
  font-size: 1.2rem;
  color: #000;
  font-family: sans-serif;
  border-style: none;
  padding: 0.6rem;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

.project .p3 .condition3,
.project .p3 .condition4,
.project .p3 .condition6 {
  display: flex;
  justify-content: center;
  padding: 2rem;
  grid-gap: 1rem;
  gap: 1rem;
  font-size: 20px;
}

.project .p3 .condition3 h3,
.project .p3 .condition4 h3,
.project .p3 .condition6 h3 {
  justify-content:center;
  text-align:center;
  margin-top:0px;
  font-size:25px;
}

.project .p3 .condition3 a,
.project .p3 .condition4 a,
.project .p3 .condition6 a {
  margin-top: 7px;
  color:black;

}


@media (max-width: 1180px) {
    body{
        overflow-x:hidden;
    }

    .project .p1 {
        grid-gap:6rem;
        gap:6rem;
        flex-direction:column;
        height:100%;
        width:70%;
        justify-content:center;
        text-align:center;
        align-items:center;
        padding-top:1.5rem;
        margin-top:10rem;

    }

    .project .p2 {
        width:100%;
    }
    
    .project .p2 img{
        width:100%;
        height:100%;


    }


    .project .p3{
        margin-top:-8rem;


    }

}

@media (max-width: 800px){
    .project .p1{
        width:90%;
    }


}

@media (max-width: 360px){
    .project .p3{
        width:90%;
}
    .project .p3 .condition2 p{
        font-size:1rem;
    }

    .project .p3 .condition1 p{
        font-size:1rem;
    }

}

@media (max-height: 800px) {
    .project {
        height: calc(100vh - 20px);
    }

    .project .p2 {
        min-height: 35%; /* Your style for max-height 750px or lower */
        margin-top: 3rem; /* Your style for max-height 750px or lower */
    }
}


@media (max-height: 710px) {
    .project {
        height: calc(100vh - 5px);
    }

    .project .p2 {
        min-height: 25%; /* Your style for max-height 750px or lower */
        margin-top: 2rem; /* Your style for max-height 750px or lower */
    }
}

@media (max-height: 620px) {
    .project {
        height: calc(100vh - 5px);
    }

    .project .p2 {
        min-height: 20%; /* Your style for max-height 750px or lower */
        margin-top: 3rem; /* Your style for max-height 750px or lower */
    }
}
