.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f9f9f9;
}

body {
    margin:0;
    padding:0;
    overflow-x:hidden;

}