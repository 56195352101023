.navbar {
  width: 100%;
  height: 100px;
  background: #fff;
  box-shadow:0px 0px 10px rgba(0,0,0,.10);
  font-family:Poppins;
}

.expanded {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  transition: 0.5s;

}

.expanded .links{
    flex-direction:column;
    display:flex;
    flex-direction:column;
}

.links {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;

}

.navbar a {
  color: black;
  text-decoration: none;
  margin: 20px;
  font-size: 25px;
}

.toggleButton {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.toggleButton svg {
  font-size: 50px;
}
.toggleButton button {
  margin-right: 20px;
  background-color: transparent;
  border: none;
  color: black;
  cursor: pointer;
}


@media only screen and (max-width: 900px) {
  .navbar a {


  }


}

@media only screen and (max-width: 600px) {
  .toggleButton {
    display: flex;

  }

  .links{
    display:none;
  }


}

@media only screen and (min-width: 600px) {
  .toggleButton {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }


}

